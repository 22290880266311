/* .galeriaTitle {
    color: whitesmoke;
    font-weight: lighter;    
    font-size: 3rem;
    margin-bottom: 2rem;
    border: 0.1rem solid rgb(14, 83, 83);    
    background-color: rgba(128, 128, 128, 0.555);
    font-family: 'Caveat', cursive;
    
} */

.carrouselImagenes {
    /* height: 450px; */
    width: 450px;             
}

.slick-prev, .slick-next {
    visibility: hidden;
    left: 0;
}

/* .slick-next {
    visibility: visible !important;
    left: 430px !important;
    z-index: 1 !important;
}

.slick-prev {
    visibility:visible !important;
    right: 0px !important;
    z-index: 1 !important;
} */

@media (max-width: 583px) {

    .carrouselImagenes {
        width: 380px;
        height: auto;              
    }
}

@media (max-width: 385px) {
  
    .carrouselImagenes {
      width: 300px;
    }
  }

