.crearNuevoProductoDiv {
    display: flex;
    justify-content: center;    
}

.titleForm {
    text-align: center;
    text-decoration: underline;
    margin-top: 3rem;
    margin-bottom: 0.5rem;
    font-family: 'Caveat', cursive;
    color: rgb(153, 148, 148);
    font-size: 2.5rem
}

.crearNuevoProductoForm {
    display: flex;
    flex-direction: column;
    padding: 2rem;    
    margin-bottom: 2rem;
    border: 0.1rem solid rgb(32, 144, 144);
    background-color:rgba(218, 213, 213, 0.486);
    border-radius: 8px;
}

.crearNuevoProductoLabel {
    color: #205c5c;
    text-align: center;
    font-weight: bolder;
    margin-bottom: 0.2rem;
}

.crearNuevoProductoInputTitle {
    border-radius: 8px;
    margin-bottom: 1rem;
}

.crearNuevoProductoInputDescription {
    border-radius: 8px;
    margin-bottom: 1rem;
}

.crearNuevoProductoInputPrice {
    border-radius: 8px;
    margin-bottom: 1rem;
}

.crearNuevoProductoInputImagen {
    margin-top: 0.5rem;
}

.divCategory {
    display: flex;
    flex-wrap: wrap;
}

.crearNuevoProductoButton {   
    padding: 0.2rem;    
    cursor: pointer;
    margin-bottom: 1rem;
    margin-top:1rem;
    border: solid 0.1rem rgba(32, 92, 92, 0.85);
    border-radius: 8px;
    background-color: rgb(32, 144, 144);
    color: rgb(234, 221, 221); 
}