

.itemDetailBody {
    display: flex;
    flex-direction: column;
    height: 598px;
    width: 570px;
}

.itemDetailHeader{
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: solid 0.1rem black;
    margin-right: 3rem;
    margin-left: 3rem;
}

.itemDetailTitle{
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 3rem;    
    color: rgb(97, 102, 99);
    font-size: 2.5rem; 
    font-weight:lighter;
}

.itemDetailPrice{
    font-size: 1.5rem;    
    color: rgb(97, 102, 99);
    font-size: 2rem;
    font-weight: lighter;
    margin-bottom: 4rem;    
}

.itemDetailDescriptionDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3rem;
}

.itemDetailDescriptionTitle {
    margin-bottom: 2rem;
    color: rgb(97, 102, 99); 
    text-decoration: underline;
    font-style: oblique;
    
}

.itemDetailDescription{
    text-align: center;    
    color: rgba(97, 102, 99, 0.822);
    font-size: 1.2rem; 
}

.itemDetailButtons {
    display: flex;
    justify-content: space-around;
    margin-top: 8rem;
    margin-bottom: 2rem;
}

.itemDetailButtonMessage {    
    cursor: pointer;     
    border: solid 0.1rem rgba(32, 144, 144, 0.637);
    border-radius: 8px;    
    background-color: rgba(32, 144, 144, 0.637);
    color: white;
    width: 175px;
    height: 40px;
}

.itemDetailButtonVolver {   
    cursor: pointer;     
    border: solid 0.1rem rgba(32, 144, 144, 0.637);
    border-radius: 8px;    
    background-color: rgba(32, 144, 144, 0.637);    
    color: white;
    width: 175px;
    height: 40px;
}

@media (max-width: 1138px) {

    .itemDetailHeader {
        margin-top: 1.5rem;
    }
}


@media (max-width: 583px) {

    .itemDetailBody {
        width: auto;
    }

    .itemDetailTitle {
        font-size: 2rem;
    }

    .itemDetailPrice {
        font-size: 1.8rem;
    }
}

@media (max-width: 385px) {

    .itemDetailBody {
        height: auto;
    }

    .itemDetailHeader {
        margin-left: 0;
        margin-right: 0;
    }

    .itemDetailTitle {
        font-size: 1.7rem;
    }

    .itemDetailPrice {
        font-size: 1.4rem;
        margin-bottom: 3rem;
    }

    .itemDetailDescription {
        font-size: 1rem;
    }

    .itemDetailButtons {
        margin-top: 5rem;
        margin-bottom: 0;
    }

    .itemDetailButtonVolver, .itemDetailButtonMessage {
       width: 120px;
    }
}
