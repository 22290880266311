.itemDetailContainerDiv {
    display: flex;
    margin: auto;
    }

/* MEDIA QUERIES */

@media (max-width: 1138px) {

    .itemDetailContainerDiv {
        flex-direction: column;
    }

}