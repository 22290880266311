.productCardDiv{
    margin-bottom: 1.5rem;
    background-color: rgba(153, 148, 148, 0.199);
    width: 400px;
    box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.2);
    transition: filter 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    border-top-right-radius: 2%;
    border-top-left-radius: 2%;       
}

.productCardDiv:hover {   
    filter: brightness(1.1);
    box-shadow: 12px 12px 12px rgba(0, 0, 0, 0.3);
}

.productCard {
    display: flex;
    flex-direction: column;
    width: 400px;
    border-top-right-radius: 2%;
    border-top-left-radius: 2%;    
       
}

.productCardImgContainter {
    display: flex;
    flex-direction: column;
    align-items: center;    
    width:400px;
    border-top-right-radius: 2%;
    border-top-left-radius: 2%;  
    /* height: 400px; */
    
}

.productCardImg{
    margin-top: auto;
    margin-bottom: auto;
    /* max-width:400px;
    max-height: 400px;             */
    width: 400px;
    border-top-right-radius: 2%;
    border-top-left-radius: 2%;
    clip-path: polygon(1% 0, 100% 0, 100% 89%, 0% 100%);
}


.productCardTitle{
    text-align: center;
    margin-bottom: 1rem;  
    color: rgb(97, 102, 99);
    font-weight: lighter;  
}

.editProductCardTitle {
    width: auto;
    text-align: center;
    margin-bottom: 1rem;
}

.productCardDescription{
    text-align: center;   
    color: rgba(97, 102, 99, 0.822);
}

.editProductCardImg {
    margin-top: 1rem;
    margin-bottom: 1rem;  
}

.editProductCardTitle {
    padding: 0.2rem;
    border-radius: 8px;
}

.editProductCardDescription {
    width: auto;
    text-align: center;
    border-radius: 8px;
    padding: 0.2rem;
}

.productCardFooter{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.productCardFooterPrice{    
    margin-bottom: 1rem;
    margin-top:1rem;
    font-size: 1.5rem;
    color: rgba(97, 102, 99, 0.822);
}

.editProductCardFooterPrice {
    padding: 0.2rem;
    width: auto;
    text-align: center;
    margin-bottom: 1rem;
    margin-top:1rem;
    font-size: 1.5rem;
    border-radius: 8px;
}

.productCardFooterButton{
    width: 150px;
    height: 35px;
    padding: 0.2rem;    
    cursor: pointer;
    margin-bottom: 1rem;
    margin-top:1rem;
    border: solid 0.1rem rgba(32, 144, 144, 0.637);
    border-radius: 8px;
    background-color: rgba(32, 144, 144, 0.637);
    color: white;
    box-shadow: 2px 2px 4px rgb(0, 0, 0, 0.2);    
}

.volverYGuardarEdicionDiv {
    display: flex;
    width: 100%;
    justify-content: space-around;    
}

                                                            /* MEDIA QUERIES */

@media (max-width: 402px) {

    .productCardDiv {
        display: flex;
        /* flex-direction: column;
        align-items: center; */
        width: 150px;       
    }

    .productCard {
        width: 150px;
        /* margin-top: 15px; */
    }

    .productCardImgContainter {
        width: 150px;
        height: 200px;
    }

    .productCardImg {
        width: 150px;
        height: 200px;        
    }

    .productCardTitle {
        height: 45px;        
        margin-top: 0;
        margin-bottom: 10px;
        font-size: 17px;
        
    }

    .productCardDescription {
        display: none;
    }

    .productCardFooterPrice {
        font-size: 20px;
        margin-bottom: 0;
        margin-top: 0;
    }

    .productCardFooterButton {
        font-size: 11px;
        margin-bottom: 15px;
        margin-top: 15px;
        width: 110px;
        height: 25px;
    }

    .editProductCardFooterPrice {
        width: 100%;
    }

}

